import Axios from 'axios';
import Serve from '../app/serve';
import formatoMoedaSemCifrao from '../components/formatoMoedaSemCifracao';

async function getPagedProcessos(page, filtro, ordenacao) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  // TRANSFORMA STRING FORMAS DE PAGAMENTO EM UM ARRAY
  const returnFormaPagamento = (value) => {
    if(!value) return [""];
    const newValue = value.trim().split(", ");
    return newValue.map((e) => e.replace(",", ""));     
  };

  await Axios.post(app + 'lista_resumida_financeiro', {
    responsavel_id: filtro.responsavel,
    page,
    forma_pagamento_id: filtro.forma_pagamento,
    gerente_id: filtro.gerente?.id,
    cliente_id: filtro.cliente.id,
    logradouro: filtro.endereco,
    periodo_inicial: filtro.periodo_inicial,
    periodo_final: filtro.periodo_final,
    ordenacao: ordenacao.valor,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (!res?.data?.processos) {
      data = false;
    }
    else if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
      data = false;
    } else {
      res.data.processos.data.forEach((process) => {
        process.forma_pagamento = returnFormaPagamento(process.forma_pagamento);
        process.valor_total_dna = formatoMoedaSemCifrao(process.valor_total_dna.toFixed(2).toString());
        process.valor_dna = formatoMoedaSemCifrao(process.valor_dna.toFixed(2).toString());
        process.valor_benesh = formatoMoedaSemCifrao(process.valor_benesh.toFixed(2).toString());
      })
      res.data.processos.contagem_geral = res.data.contagem_geral;
      data = res.data.processos;
      // res.data.data_entrada.forEach((datas, index) => {
      //   data.processos.data[index].data = datas;
      // });
    }
  })

  return data;
};

export default getPagedProcessos;