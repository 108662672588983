import React, { useEffect, useContext } from 'react';
import {
  Pane,
  Table,
  Button,
  Badge,
  Avatar,
  Select,
  FilterIcon,
  Autocomplete,
  TextInput,
  Tooltip,
  Combobox,
  Pagination,
  Position,
  CalendarIcon,
  SearchIcon
} from 'evergreen-ui';
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loadingDocs.json';

//Context
import FinanceiroContext from '../../../context/FinanceiroContext';

//Components
import ChangeLog from '../../components/ChangeLog';
import SideSheetView from '../components/SideSheetView';

// APIS
import getProcessById from '../../../apis/getProcessById';

import dateMask from '../../../components/dateMask';

// LARGURA DOS CAMPOS NA TABLEROW
const width = {
  responsavel: 70,
  valor_receber: 130,
  data_prevista: 140,
  gerente: 140,
  data_assinatura: 110,
  forma_pagamento: 140,
};

export default function TablePanel({ getPaged }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const {
    user,
    loading,
    setProcessoId,
    processoId,

    //Table Data
    tablePaged,

    //SIDESHEET
    setIsShownSide, setProcessSelect,
    processSelect, setSelectedIndex,

    //Filtros
    filtro, emptyComboFilter,
    quantFiltro, setQuantFiltro,
    listaResponsaveis, listaGerente, listaClientes,
    listaEndereco,
    listFormasPagamento,

    // Ordenação desativada
    // ordenacao,

    //Paginação
    pageNumber, setPageNumber,
    totalPaginas,
    totalProcessos,
    totalProcessosFiltrados,

    dataInicio, setDataInicio,
    dataFim, setDataFim
  } = useContext(FinanceiroContext);

  useEffect(() => {
    if(dataInicio === '') setDataInicio(localStorage.getItem('filtro_periodo_inicial') || '');
    if(dataFim === '') setDataFim(localStorage.getItem('filtro_periodo_final') || '');
    quantidadeFiltros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sideFunction = async (id, index, tabIndex) => {
    if (!processoId || id !== processSelect.id || !(index >= 0)) {
      setProcessoId(id);
      if (tabIndex) setSelectedIndex(tabIndex);
      else setSelectedIndex(0);
      const dataProcess = await getProcessById(id);
      setProcessSelect(dataProcess);
    }
    setIsShownSide(true);
  };

  const handleClearFilter = async () => {
    // Limpa os campos de data
    setDataInicio('');
    setDataFim('');

    filtro.responsavel = '';
    filtro.forma_pagamento = '';
    filtro.gerente = emptyComboFilter;
    filtro.cliente = emptyComboFilter;
    filtro.endereco = '';
    filtro.periodo_inicial = '';
    filtro.periodo_final = '';
    // setFiltro({...filtro});

    localStorage.removeItem('filtro_forma_pagamento');
    localStorage.removeItem('filtro_responsavel');
    localStorage.removeItem('filtro_gerente');
    localStorage.removeItem('filtro_cliente');
    localStorage.removeItem('filtro_endereco');
    localStorage.removeItem('filtro_periodo_inicial');
    localStorage.removeItem('filtro_periodo_final');
    //localStorage.removeItem('ordenacao');

    handleFilter(1);
  };

  const handleFilter = async (page) => {
    setPageNumber(page);
    quantidadeFiltros();
    localStorage.setItem('page_number', page);
    getPaged(page);
  };

  const quantidadeFiltros = async () => {
    let filtrosSelecionados = 0;
    if (filtro.responsavel !== '') {
      filtrosSelecionados++;
    }
    if (filtro.forma_pagamento !== '') {
      filtrosSelecionados++;
    }
    if (filtro.gerente?.id !== '') {
      filtrosSelecionados++;
    }
    if (filtro.cliente?.id !== '') {
      filtrosSelecionados++;
    }
    if (filtro.endereco !== '') {
      filtrosSelecionados++;
    }
    if (filtro.periodo_inicial !== '') {
      filtrosSelecionados++;
    }
    if (filtro.periodo_final !== '') {
      filtrosSelecionados++;
    }
    setQuantFiltro(filtrosSelecionados);
  };

  //Filtro de logradouro
  function handleFilterEndereco(e) {
    filtro.endereco = e;
    localStorage.setItem('filtro_endereco', e);
    handleFilter(1);
  };

  // Filtro de Responsavel e de Status
  const handleSelect = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    localStorage.setItem('filtro_' + name, value);
    filtro[name] = value;
    handleFilter(1);
  };

  // Filtro de Combobox
  const handleCombobox = (e, campo) => {
    if (e) {
      filtro[campo] = e;
      localStorage.setItem('filtro_' + campo, JSON.stringify(e));
      handleFilter(1);
    }
  };

  // ORDENAÇÃO DESATIVADA
  // const handleSelectOrdenacao = (e) => {
  //   const value = e.target.value;
  //   localStorage.setItem('ordenacao', value);
  //   ordenacao.valor = value;
  //   handleFilter(1);
  // };  

  // const selectOrdenacao = [
  //   {
  //     value: '1',
  //     name: 'Todos'
  //   },
  //   {
  //     value: '2',
  //     name: 'Prazo: maior (tempo sobrando)'
  //   },
  //   {
  //     value: '3',
  //     name: 'Prazo: menor (tempo sobrando)'
  //   },
  //   {
  //     value: '8',
  //     name: 'Data de entrada: Crescente'
  //   },
  //   {
  //     value: '9',
  //     name: 'Data de entrada: Decrescente'
  //   },
  // ];

  const handleDate = (e, type) => {
    const value = dateMask(e.target.value);
    if(type === 'inicial'){
      setDataInicio(value)
      if(value.length === 10) filtro.periodo_inicial = value;
      else filtro.periodo_inicial = '';
    }
    else{
      setDataFim(value);
      if(value.length === 10) filtro.periodo_final = value;
      else filtro.periodo_final = '';
    }

    // Se os dois valores estiverem em localStorage, mas algum dos campos tiverem menos de 10 caracteres, remove do refresh do filtro
    // Se não, se um dos campos forem editados perdendo 10 caracteres, recupera o valor atribuido ao filtro no localStorage de cada um
    if(localStorage.getItem('filtro_periodo_inicial') && localStorage.getItem('filtro_periodo_final')){
      if(filtro.periodo_inicial.length < 10 || filtro.periodo_final.length < 10){
        localStorage.removeItem('filtro_periodo_inicial');
        localStorage.removeItem('filtro_periodo_final');
      }
    }
    else{
      if(filtro.periodo_inicial.length === 10 && filtro.periodo_final.length === 10){
        localStorage.setItem('filtro_periodo_inicial', filtro.periodo_inicial);
        localStorage.setItem('filtro_periodo_final', filtro.periodo_final);
      }
    }
  }

  const handleFiltrarPeriodo = () => {
    if(dataInicio.length === 10 && dataFim.length === 10){
      handleFilter(1);
    }
  }

  // console.log('FILTROS: ' , filtro)

  return (
    <Pane>
      <div className='gap13 mt22 mb22'>
        <h2 className="fw600">Histórico de Vendas e Comissões</h2>
        <p>Olá, {user.name}! Aqui você pode acompanhar todos os processos e informações de previsão de faturamento. </p>
      </div>

      <h2 className="subtitle subtitle-table" style={{ padding: "0px" }}>
        Processos {
          totalProcessos > 0 &&
          <Tooltip
            content="Numero total de processos"
          >
            <Badge color="blue" marginLeft={10}>
              {totalProcessos}
            </Badge>
          </Tooltip>
        }
      </h2>
      <div className='flex space-between align mb30'>
        <div className="flex gap20 end itemsCenter">

          <div className='flex'>
            <FilterIcon color={'#8F95B2'} />
            <span className="fw400 ft12"> Filtrar por: </span>
          </div>


          <div>
            <Select
              label=""
              name='responsavel'
              value={filtro.responsavel}
              onChange={(e) => handleSelect(e)}
              id="filtro-responsavel"
              className={filtro.responsavel === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
            >
              <option value="" hidden>Responsável</option>
              {listaResponsaveis.map((responsavel) =>
                <option key={responsavel.id} value={responsavel.id}>{responsavel.name}</option>
              )}
            </Select>
          </div>


          <div>
            <Combobox
              openOnFocus
              name='gerente'
              items={listaGerente}
              selectedItem={filtro.gerente}
              itemToString={item => (item ? item.name : '')}
              placeholder="🔍︎ Gerente"
              onChange={(e) => handleCombobox(e, 'gerente')}
              style={{ marginLeft: '0' }}
              id="filtro-gerente"
            // className={filtro.gerente === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            />
          </div>

          <div>
            <Combobox
              openOnFocus
              name='cliente'
              items={listaClientes}
              selectedItem={filtro.cliente}
              itemToString={item => (item ? item.name : '')}
              placeholder="🔍︎ Clientes"
              onChange={(e) => handleCombobox(e, 'cliente')}
              style={{ marginLeft: '0' }}
              id="filtro-cliente"
            // className={filtro.gerente === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
            />
          </div>

          <div>
            <Autocomplete
              onChange={(e) => (handleFilterEndereco(e))}
              items={listaEndereco}
              selectedItem={filtro.endereco}
              allowOtherValues={true}
            >
              {props => {
                const { getInputProps, getRef, inputValue, openMenu } = props
                return (
                  <TextInput
                    placeholder="🔍︎ Endereço"
                    value={inputValue}
                    ref={getRef}
                    {...getInputProps({
                      onClick: () => {
                        openMenu()
                      }
                    })}
                  />
                )
              }}
            </Autocomplete>
          </div>

          <div>
            <Select
              label=""
              name='forma_pagamento'
              value={filtro.forma_pagamento}
              minWidth={169}
              onChange={(e) => handleSelect(e)}
              id="forma_pagamento"
              style={{ marginLeft: '0' }}
              className={filtro.forma_pagamento === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
            >
              <option value="" hidden selected >Formas de pagamento</option>
              {listFormasPagamento.map((valor) =>
                <option key={Number(valor.value)} value={valor.value}>{valor.label}</option>
              )}
            </Select>
          </div>

          <div>
            {/* <div className="label-align-filter-order">
              <ChangesIcon color={'#8F95B2'} /> <span className="label-filter-order"> Ordenar por: </span>
            </div> */}

            {/*Ordenação*/}
            {/* <div className='filter-field'>
              <Select
                width={230}
                label=""
                name='ordenacao'
                value={ordenacao.valor}
                onChange={(e) => handleSelectOrdenacao(e)}
                id="ordenacao"
                className={ordenacao === "" ? "filtro-select color-select-filter-hidden" : "filtro-select color-select-filter"}
              >
                {selectOrdenacao.map((ordenar, index) => <option key={index} value={ordenar.value}>{ordenar.name}</option>)}
              </Select>
            </div> */}
          </div>

        </div>
      </div>

      <div className='flex space-between align mb30' style={{border: '1px solid #9DB5FF', borderRadius: '10px', padding: '10px', width: '100%', maxWidth: '425px'}}>
        <div className="flex gap20 end itemsCenter">
          <div className="flex" style={{alignItems: 'center'}}>
            <div className="flex" style={{alignItems: 'center'}}>
              <CalendarIcon color={'#8F95B2'} style={{marginRight: '6px'}} />
              <span className="fw400 ft12" style={{marginRight: '4px'}}>De: </span>
              <TextInput 
                id="periodo_inicial" 
                name="periodo_inicial" 
                value={dataInicio || ''} 
                placeholder="DD/MM/AAAA" 
                width={140} 
                maxlength="10" 
                onChange={e => handleDate(e, 'inicial')} 
              />
            </div>
            <div>
                <span className="fw400 ft12" style={{marginLeft: '4px'}}>até: </span>
                <TextInput 
                  id="periodo_final" 
                  name="periodo_final" 
                  value={dataFim || ''} 
                  placeholder="DD/MM/AAAA" 
                  width={140} 
                  maxlength="10" 
                  onChange={e => handleDate(e, 'final')} 
                />
              </div>
            </div>

          
          <div>
            <Button appearance="primary" disabled={(dataInicio.length === 10 && dataFim.length === 10) ? false : true} onClick={e => handleFiltrarPeriodo()}><SearchIcon color={'#fff'}/></Button>
          </div>
        </div>
      </div>


      <div className="filter-info">
        <div>
          {quantFiltro > 0 &&
            <p>
              <FilterIcon className="icon-filter" />
              {quantFiltro} {quantFiltro === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
              {tablePaged.length} / {totalProcessosFiltrados} {totalProcessosFiltrados === 1 ? 'processo visível' : 'processos visíveis'}.
              <span onClick={(e) => handleClearFilter(e)}> Limpar filtro</span>
            </p>
          }
        </div>
        <div className='filter-table filter-table-nav'>
          <Pagination
            page={pageNumber}
            totalPages={totalPaginas}
            onPageChange={(e) => !loading ? handleFilter(e) : ''}
            onNextPage={() => handleFilter(pageNumber + 1)}
            onPreviousPage={() => handleFilter(pageNumber - 1)}
          />
        </div>
      </div>

      <div className='Rectangle35 Blue'></div>
      <Table.Body className="table">
        <Table.Head className="f10">
          <Table.TextCell flexBasis={width.responsavel} className="table">
            Responsável
          </Table.TextCell>
          <Table.TextCell className="table minW70_30vw">
            Endereço
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={width.valor_receber}>
            Valor <br />DNA Imóveis
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={width.valor_receber}>
            Valor <br />Benesh
          </Table.TextCell>

          <Table.TextCell className="table bg-table-blue" flexBasis={width.valor_receber}>
            Valor total<br /> a receber
          </Table.TextCell>

          <Table.TextCell className="table bg-table-blue pl0" flexBasis={width.data_prevista}>
            Data Prevista
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={width.gerente}>
            Gerente
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={width.data_assinatura}>
            Data de<br />  Assinatura
          </Table.TextCell>

          <Table.TextCell className="table" flexBasis={width.forma_pagamento}>
            Forma de<br />  Pagamento
          </Table.TextCell>

        </Table.Head>
        {
          //Enquanto não carregar os dados exibe um Spinner
          loading &&
          <div className='backgroundLoadingDocs'><Lottie options={defaultOptions} height={200} width={400} /></div>
        }
        {
          //Exibe os dados carregados
          !loading &&
          <>
            <Table.Body>
              {tablePaged.map((processo, index) =>
                <Table.Row key={index} height="auto" minHeight="64px" isSelectable onClick={() => sideFunction(processo.id, index)}>
                  {/* EXIBE CAMPO AVATAR RESPONSAVEL */}
                  <Table.TextCell flexBasis={width.responsavel} className="table" >
                    <Avatar name={processo.status_processo_atual === 26 ? "Franqueado" : processo.responsavel_atual_nome} size={40} marginRight={16} />
                  </Table.TextCell>

                  {/* EXIBE CAMPO ENDEREÇO */}
                  <Table.TextCell className="table table-cell minW70_30vw">
                    <div>
                      <Tooltip
                        content={processo.logradouro + ', ' + processo.numero + (processo?.unidade ? ' / ' + processo.unidade : '')}
                        position={Position.BOTTOM}
                        isShown={processo.logradouro.length < 38 ? false : undefined}
                      >
                        <div className="dot-text">{processo.logradouro}, {processo.numero} {processo.unidade ? '/ ' + processo.unidade : ''}</div>
                      </Tooltip>
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMPO VALOR DNA IMOVEIS */}
                  <Table.TextCell flexBasis={width.valor_receber} className="table table-cell">
                    {`R$ ${processo.valor_dna || '0,00'}`}
                  </Table.TextCell>

                  {/* EXIBE CAMPO VALOR BENESH */}
                  <Table.TextCell flexBasis={width.valor_receber} className="table table-cell">
                    {`R$ ${processo.valor_benesh || '0,00'}`}
                  </Table.TextCell>

                  {/* EXIBE CAMPO VALOR TOTAL A RECEBER */}
                  <Table.TextCell flexBasis={width.valor_receber} className="table table-cell bg-table-blue">
                    {`R$ ${processo.valor_total_dna || '0,00'}`}
                  </Table.TextCell>

                  {/* EXIBE CAMPO DATA PREVISTA */}
                  <Table.TextCell flexBasis={width.data_prevista} className="table table-cell bg-table-blue pt5 pb5 pl0">
                    {processo.previsao_parcelas.length === 0 && "A ser informada"}
                    {processo.previsao_parcelas.map((parcela, index) => (
                      <div key={parcela.id} className='fw400 pt5 pb5'>
                        {processo.previsao_parcelas.length > 1
                          ? `${index + 1}ª ${parcela.data_comissao || "A ser informada"}`
                          : parcela.data_comissao || "A ser informada"
                        }
                      </div>
                    ))}
                  </Table.TextCell>

                  {/* EXIBE CAMPO NOME DO GERENTE */}
                  <Table.TextCell className="table table-cell" flexBasis={width.gerente} >
                    <div>
                      {processo.gerente_atual_nome
                        ? <div>
                          <Tooltip content={processo.gerente_atual_nome} isShown={processo.gerente_atual_nome.length < 15 ? false : undefined} position={Position.BOTTOM}>
                            <div className="dot-text">{processo.gerente_atual_nome}</div>
                          </Tooltip>
                        </div>
                        : <Button
                          // onClick={() => setSelectedIndex(1)}
                          marginRight={16}
                          className="btn-adicionar"
                        >
                          + Adicionar
                        </Button>
                      }
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMPO DATA DE ASSINATURA */}
                  <Table.TextCell className="table table-cell" flexBasis={width.data_assinatura} >
                    <div>
                      {processo.data_assinatura}
                    </div>
                  </Table.TextCell>

                  {/* EXIBE CAMPO FORMA DE PAGAMENTO */}
                  <Table.TextCell className="table table-cell pt5" flexBasis={width.forma_pagamento} >
                    <div>
                      {processo.forma_pagamento.map(value =>
                        <div key={value} className='pb5'>
                          <Badge color='blue' >{value || "SEM CADASTRO"}</Badge>
                        </div>
                      )}
                    </div>
                  </Table.TextCell>
                </Table.Row>
              )}
            </Table.Body>
            {/*Table de processos finalizados*/}
          </>
        }
      </Table.Body>

      <SideSheetView />
      <ChangeLog data="Financeiro" />

    </Pane>
  )
}