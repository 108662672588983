import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import Navbar from '../../components/Navbar';
import TablePanel from './components/TablePanel';
import FinanceiroContext from '../../context/FinanceiroContext';

// APIS
import getResponsaveis from '../../apis/getResponsaveis';
import ListarStatus from '../../apis/getStatusListProcessos';
import ListarGerentes from '../../apis/getGerenteList';
import ListarClientes from '../../apis/getClienteList';
import ListaEnderecos from '../../apis/getEnderecos';
import getLojas from '../../apis/getLojas';
import getLaudemiosList from '../../apis/getLaudemiosList';
import getListEscrituras from '../../apis/getListEscrituras';
import getPagedProcessos from '../../apis/getPagedFinanceiroTable';

export default function Negociacoes() {
  const [perfil_login] = useState(localStorage.getItem('perfil_login'));
  const history = useHistory();
  const [user] = useState({
    id: localStorage.getItem('usuario_id'),
    name: localStorage.getItem('nome_usuario'),
    perfil_id: localStorage.getItem('perfil_id'),
  });

  if (perfil_login !== "Financeiro") {
    history.push('/');
  }

  //Context
  const [loading, setLoading] = useState(false);
  const [processoId, setProcessoId] = useState();

  //SIDESHEET
  const [isShownSide, setIsShownSide] = useState(false);
  const [tabsVisualization, setTabsVisualization] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [processSelect, setProcessSelect] = useState({});

  // LISTA SIDESHEET
  const [listLaudemios, setListLaudemios] = useState([]);
  const [listEscrituras, setListEscrituras] = useState([]);

  //LISTAS FILTROS
  const [listaResponsaveis, setListaResponsaveis] = useState([]);
  const [listaStatusProcesso, setListaStatusProcesso] = useState([]);
  const [listaGerente, setListaGerente] = useState([]);
  const [listaClientes, setListaCliente] = useState([]);
  const [listaEndereco, setListaEndereco] = useState([]);  
  const [listaLojas, setListaLojas] = useState([]);
  const [listFormasPagamento] = useState([
    { label: "À vista", value: "1" },
    { label: "Financiamento", value: "2" },
    { label: "FGTS", value: "3" },
    { label: "Consórcio", value: "4" },
    { label: "Parcelamento", value: "5" },
    { label: "PIX", value: "6" },
  ]);

  // FILTRO PERIODO
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('')

  //Filtros
  const [emptyComboFilter] = useState({ id: '', name: '' });
  const [filtro] = useState({
    'responsavel': Number(localStorage.getItem('filtro_responsavel')) || '',
    'forma_pagamento': Number(localStorage.getItem('filtro_forma_pagamento')) || '',
    'gerente': JSON.parse(localStorage.getItem('filtro_gerente')) || emptyComboFilter,
    'cliente': JSON.parse(localStorage.getItem('filtro_cliente')) || emptyComboFilter,
    'endereco': localStorage.getItem('filtro_endereco') || '',
    'periodo_inicial': localStorage.getItem('filtro_periodo_inicial') || '',
    'periodo_final': localStorage.getItem('filtro_periodo_final') || '',
  });
  const [quantFiltro, setQuantFiltro] = useState([]);

  //ORDENAÇÂO DESATIVADO
  const [ordenacao] = useState({
    'valor': localStorage.getItem('ordenacao') || ''
  });

  //PAGINAÇÃO 
  const [pageNumber, setPageNumber] = useState(Number(localStorage.getItem('page_number')) || 1);
  const [totalProcessosFiltrados, setTotalProcessosFiltrados] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState(2);
  const [totalProcessos, setTotalProcessos] = useState(0);
  const [tablePaged, setTablePaged] = useState([]);

  //DOCUMENTOS
  const [formValues, setFormValues] = useState({
    'imovel': [{ 'documento': '', 'arquivo': '' }],
    'vendedores': [],
    'compradores': []
  });

  const conxtextStatus = {
    user,
    loading,
    setLoading,
    processoId, setProcessoId,

    //SIDESHEET
    isShownSide, setIsShownSide,
    processSelect, setProcessSelect,
    tabsVisualization, setTabsVisualization,
    selectedIndex, setSelectedIndex,
    formValues, setFormValues,

    // LIST SELECTS
    listLaudemios,
    listEscrituras,

    //Table data
    tablePaged, setTablePaged,

    //Filtros
    filtro, emptyComboFilter,
    quantFiltro, setQuantFiltro,
    listaResponsaveis, listaGerente, listaClientes,
    listaStatusProcesso, listaEndereco,
    listFormasPagamento,

    //Ordenação
    ordenacao,

    //Paginação
    pageNumber, setPageNumber,
    totalPaginas, setTotalPaginas,
    totalProcessos,
    totalProcessosFiltrados,

    listaLojas, setListaLojas,

    dataInicio, setDataInicio,
    dataFim, setDataFim
  };

  useEffect(() => {    
    getListFiltros();
    getTable(pageNumber);
    getListSelectsSideSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getListFiltros() {
    const lojas = await getLojas();
    setListaLojas(lojas);

    const responsaveis = await getResponsaveis();
    setListaResponsaveis(responsaveis);

    const status = await ListarStatus();
    setListaStatusProcesso(status);

    const gerentes = await ListarGerentes();
    setListaGerente(gerentes);

    const clientes = await ListarClientes();
    setListaCliente(clientes);

    const logradouros = await ListaEnderecos();
    setListaEndereco(logradouros);    
  };

  async function getListSelectsSideSheet () {
    const laudemios = await getLaudemiosList();
    setListLaudemios(laudemios);

    const listEscriturasData = await getListEscrituras();
    setListEscrituras(listEscriturasData);
  };

  async function getTable(page) {
    setLoading(true);
    const data = await getPagedProcessos(page, filtro, ordenacao);
    setTablePaged(data.data);
    setTotalPaginas(Math.ceil(data.last_page));
    setTotalProcessos(data.contagem_geral);
    setTotalProcessosFiltrados(data.total);
    setLoading(false);
  };

  return (
    <FinanceiroContext.Provider value={conxtextStatus}>
      <Navbar Context={FinanceiroContext} />
      <div className="painel mt59">
        <div className="flex-container">
        </div>
        <TablePanel getPaged={getTable} />
      </div>
    </FinanceiroContext.Provider>
  )
}
